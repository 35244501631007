<!--
 * @Author: your name
 * @Date: 2022-04-06 16:50:57
 * @LastEditTime: 2022-04-11 15:21:56
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\views\management\equipment_schools_detail.vue
-->
<!--
 * @Author: your name
 * @Date: 2022-04-06 14:19:48
 * @LastEditTime: 2022-04-06 16:32:25
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \syg_pc_web\src\views\management\equipment_detail.vue
-->
<template>
  <div id="body">
    <div class="fatherBox">
      <div class="headerBox" style="border-bottom:0px solid #001528">
        <div class="headerinfos">
          <p @click="$router.go(-2)">设备数据管理</p>
          <p>/</p>
          <p @click="$router.go(-1)">{{ search_date }}</p>
          <p>/</p>
          <p>{{ school_title }}</p>
        </div>
      </div>

      <el-table :data="schools_list" border style="width: 100%">
        <el-table-column prop="date" label="序号" width="200" align="center">
          <template slot-scope="scope">
            {{ (curr_page - 1) * page_size + scope.$index + 1 }}
          </template>
        </el-table-column>

        <el-table-column prop="machinename" label="设备编号" v-if="(type == 1 || type == 5)"></el-table-column>
        <el-table-column prop="MechineName" label="设备编号" v-if="type == 6 "></el-table-column>
        <el-table-column prop="ptel_num" label="普通电话次数" v-if="type == 6"></el-table-column>
        <el-table-column prop="ptel_time" label="普通电话时长" v-if="type == 6"></el-table-column>
        <el-table-column prop="ytel_num" label="语音通话次数" v-if="type == 6"></el-table-column>
        <el-table-column prop="ytel_time" label="语音电话时长" v-if="type == 6"></el-table-column>
        <el-table-column prop="stel_num" label="视频通话次数" v-if="type == 6"></el-table-column>
        <el-table-column prop="stel_time" label="视频通话时长" v-if="type == 6"></el-table-column>
        <el-table-column prop="ssum" label="检测人数" v-if="type == 1"></el-table-column>
        <el-table-column prop="jsl" label="检测近视率" v-if="type == 1"></el-table-column>
        <el-table-column prop="cid" label="设备编号" v-if="type == 4">
        </el-table-column>
        <el-table-column prop="rnum" label="进校人数" v-if="type == 4"></el-table-column>
        <el-table-column prop="cnum" label="出校人数" v-if="type == 4"></el-table-column>
        <el-table-column prop="rynum" label="进校异常人数" v-if="type == 4"></el-table-column>
        <el-table-column prop="cynum" label="进校异常人数" v-if="type == 4"></el-table-column>
        <el-table-column prop="tsnum" label="推送打开率" v-if="type == 4"></el-table-column>
        <el-table-column prop="ssum" label="检测人数" v-if="type == 5"></el-table-column>
        <!-- <el-table-column prop="ssum" label="检测近视率" v-if="type == 5"></el-table-column> -->
        <el-table-column fixed="right" label="操作" width="100" key="slot">
          <template #default="scope">
            <el-button size="mini" type="primary" plain @click="detail(scope.row)">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="block">
        <el-pagination @current-change="handleChange" :current-page.sync="curr_page" :page-size="page_size" background
          layout="total,prev, pager, next, jumper" :total="count_num" style="margin-top: 20px">
        </el-pagination>
      </div>
    </div>

  </div>
</template>

<script>
  import {
    machine_details_video_day,
    machine_details_eye_day,
    machine_details_camera_day_sc,
    machine_details_ygy_day
  } from '@/api/agent_newApi.js'

  export default {
    data() {
      return {
        schools_list: [], // 数据列表
        curr_page: 1, // 初始页
        page_size: 10, // 每页显示条目个数
        count_num: 0, // 总条数
        search_date: '', //查询时间
        type: '', //类型序号
        school_id: '',
        school_title: '',
      }
    },
    created() {
      this.type = this.$route.params.type; // 类型序号
      this.search_date = this.$route.params.date; // 类型序号
      this.school_id = this.$route.params.school_id; // 类型序号
      this.school_title = '';
      if (localStorage.getItem('school_title') != 'null') {

        this.school_title = localStorage.getItem('school_title');
        // localStorage.setItem('school_title', null)
      }
    },
    mounted() {
      this.get_list(this.type, 1)

    },
    methods: {
      //获取数据
      get_list(type, curr_page) {
        this.loading = true
        if (type == 6) {
          const data = {
            limit: this.page_size,
            page: this.curr_page,
            day: this.search_date,
            schoolid: this.school_id
          };
          machine_details_video_day(data).then(res => {
            console.log('res', res);
            if (res.status.code == 1) {
              this.schools_list = res.data.List // 数据
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else if (type == 1) {
          const data = {
            limit: this.page_size,
            page: this.curr_page,
            day: this.search_date,
            schoolid: this.school_id
          };
          machine_details_eye_day(data).then(res => {

            if (res.status.code == 1) {

              this.schools_list = res.data.List // 数据
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else if (type == 4) {
          const data = {
            limit: this.page_size,
            page: this.curr_page,
            day: this.search_date,
            schoolid: this.school_id
          };
          machine_details_camera_day_sc(data).then(res => {

            if (res.status.code == 1) {

              this.schools_list = res.data.List // 数据
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else if (type == 5) {
          const data = {
            limit: this.page_size,
            page: this.curr_page,
            day: this.search_date,
            schoolid: this.school_id
          };
          machine_details_ygy_day(data).then(res => {

            if (res.status.code == 1) {

              this.schools_list = res.data.List // 数据
              this.count_num = parseInt(res.data.CountNum) // 总条数
              this.loading = false
              //在分页里面加入 :current-page.sync="currpage" 使第一次查询时 分页在第一页
              this.curr_page = curr_page
            } else {
              this.$message.error(res.status.msg);
            }
          }).catch(err => {
            console.log(err)
          })
        } else {
          this.$message.error('分类不存在');
        }
      },
      handleChange(val) {
        this.curr_page = val
        this.get_list(this.type, val);
      },

      detail(row) { // 点击详情
        localStorage.setItem('Route', 'health') // 存跳转标识
        localStorage.setItem('healthlist', this.currpage) // 存跳转页面
        var path = this.type == 1 ? 'facilitydetail' : this.type == 4 ? 'facilitydetailXyws' : this.type == 5 ?
          'facilitydetailXyws' : this.type ? 'facilitydetailXyws' : '';

        this.$router.push({
          name: path,
          params: {
            detailInfo: (this.type == 1 || this.type == 5 || this.type == 6) ? row.MechineID : this.type == 4 ? row
              .cid : '',
            type: this.type,
          },
        })
      },

    }
  }

</script>

<style lang="scss" scoped>
  .fatherBox {
    width: 98%;
    display: flex;
    background: white;
    margin: 0 auto;
    flex-direction: column;

    .headerBox {
      display: flex;
      height: 52px;
      width: 98%;
      align-items: center;
      margin-left: 25px;
      border-bottom: 2px solid #e9e9e9;

      .facility {
        display: flex;

        p {
          color: burlywood;

        }

        p:nth-child(3) {
          color: black;
        }
      }

      .headerinfos {
        display: flex;

        p {
          color: burlywood;

        }

        p:nth-child(5) {
          color: black;
        }

        p:nth-child(1) {
          cursor: pointer;
        }
      }

      p {
        font-size: 16px;
        font-family: 萍方-简;
        margin-left: 10px;
      }

      .stulist {
        color: #8C8C8C;
        cursor: pointer;
        // margin: 0 3px 0 32px;
      }
    }

    .headinfo {
      width: 745px;
      display: flex;
      margin-left: 100px;

      div {
        width: 373px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  .ClassInfo_box {
    width: 96%;
    margin: 0 auto;
    background: white;
    margin-top: 25px;

    .chartTitle {
      height: 51px;
      display: flex;
      align-items: center;

      // justify-content: space-between;
      //   border-bottom:1px solid #cccccc ;
      .head_dates {
        display: inherit;
        margin-left: 102px;
        font-weight: bold;
      }

      .facility {
        font-size: 16px;
        line-height: 24px;
        color: #1890FF;
        margin-left: 32px;
        font-family: PingFangSC-Medium;
      }

      ul {
        padding: 0;
        margin: 0;
        font-size: 14px;
        margin-left: 54px;

        li {
          float: left;
          list-style: none;
          padding-left: 33px;
        }

        li:hover {
          cursor: pointer
        }
      }
    }
  }

  .active {
    //检测人滑动点击css
    color: #1890FF;
    font-size: 14px;
  }

</style>
